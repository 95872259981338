* {
  margin: 0;
  box-sizing: border-box;
}

body {
  font-size: 13px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
}

a {
  color: inherit;
  text-decoration: none;
  &:hover {
    color: #212d3a;
  }
}

#app {
  max-width: 960px;
  min-height: 100vh;
  margin: 0 auto;
  padding: 2em 4em 8em 4em;
  position: relative;
}

.lang {
  cursor: pointer;
  position: absolute;
  top: 2em;
  right: 2em;
  width: 1.75em;
  height: 1.75em;
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
}

h1 {
  color: #394956;
  font-size: 1.8em;
  text-align: center;
  margin-bottom: 0.25em;
}

.subtitle {
  text-align: center;
  font-style: italic;
  font-size: 1.1em;
  color: #212d3a;
  margin-bottom: -0.75em;
}

.block {
  color: #212d3a;
  h2 {
    color: #878e94;
    font-size: 1.2em;
    font-weight: 500;
    line-height: 1em;
    display: flex;
    align-items: center;
    margin-top: 2.5em;
    margin-bottom: 1em;
    &::before {
      content: "";
      background-color: #ea7270;
      width: 0.4em;
      height: 1em;
      margin-right: 0.4em;
    }
  }
  h3 {
    display: inline-block;
    color: #2c3743;
    font-size: 1.1em;
    line-height: 1;
    margin-bottom: 0.5em;
  }
  .content {
    padding: 0 3em 0 2em;
    p {
      line-height: 1.5em;
      margin-bottom: 0.25em;
      &.contact {
        display: flex;
        align-items: center;
        margin-left: -0.75em;
        margin-bottom: 0.3em;
        svg {
          width: 1.75em;
          height: 1.75em;
          margin-right: 0.5em;
        }
      }
    }
  }
  p.meta {
    color: #878e94;
    font-style: italic;
    margin-bottom: 0.5em;
  }
  .work {
    margin-top: 1.5em;
    margin-bottom: 1em;
    h3 {
      background: linear-gradient(
        to bottom,
        #ffffff 0%,
        #ffffff 60%,
        #fbeaa6 60%,
        #fbeaa6 100%
      );
      padding: 0.25em 0.4em;
      margin-left: -1em;
    }
  }
  .project {
    margin-top: 1.5em;
    margin-bottom: 1em;
    h3 {
      display: flex;
      align-items: center;
      margin-left: -1.1em;
      &::before {
        content: "";
        background-color: #53b154;
        border-radius: 50%;
        width: 0.6em;
        height: 0.6em;
        margin-right: 0.5em;
      }
    }
  }
}

@media print {
  #app {
    padding: 2em 1em 0em 1em;
  }
  .lang {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  body {
    font-size: 13px;
  }
  #app {
    padding: 2em;
  }
}
